
const Footer = () => {
  return (
    <footer >
      <span id="copyright">Artem Mashin © <span id="year">2024</span></span>
      <span id="cat">🐈‍⬛</span>
    </footer>
  );
}
 
export default Footer;
